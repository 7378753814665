import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import getUrlAgenciasTransporteByLinea from "../services/getUrlAgenciasTransporte";
import Colectivos from "../components/Colectivos";
import Subtes from "../components/Subtes";
import Trenes from "../components/Trenes";
import Mapa from "../components/Mapa";
import Map from "../components/Map";
import OpenStreetMapView from "../components/Maps/OpenStreetMapView";
import ContactUs from "../components/ContactUs";
import getUrlApiPosicionColectivoByIdRecorrido from "../services/getUrlApiPosicionColectivoByIdRecorrido";
import getUrlApiGetPosicionByAgencAsyncy from "../services/getUrlApiGetPosicionByAgencAsyncy";
import getUrlApiGetPosicionSimpleByAgencAsync from "../services/getUrlApiGetPosicionSimpleByAgencAsync";
import getUrlApiParadasColectivoByIdRecorrido from "../services/getUrlApiParadasColectivoByIdRecorrido";
import transformColectivoJsonToListOfMarker from "../services/transformColectivoJsonToListOfMarker";
import transformColectivoSimpleJsonToListOfMarker from "../services/transformColectivoSimpleJsonToListOfMarker";
import transformParadasToListOfMarker from "../services/transformParadasToListOfMarker";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrainIcon from "@material-ui/icons/Train";
import RecentActors from "@material-ui/icons/RecentActors";
import Info from "@material-ui/icons/Info";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import MenuIcon from "@material-ui/icons/Menu";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from "react-share";
import LoadingOverlay from "react-loading-overlay";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: true,
  });
  const [markers, setMarkers] = useState([]);
  const [stops_markers, setStops_markers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostrarMapa, setMostrarMapa] = useState(true);
  const [nombreLinea, setNombreLinea] = useState(null);
  const [localizacionUsuario, setLocalizacionUsuario] = useState({});
  const [recorridosColectivos, setRecorridosColectivos] = useState([]);
  const [mapProps, setMapProps] = useState({
    options: { center: { lat: 20, lng: 40 }, zoom: 11 },
    onMount: addMarkers,
    onMountProps: { markers },
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const handleDrawerClose = (mostrarMapa) => {
    setMostrarMapa(mostrarMapa);
    setMarkers([]);
  };

  const handleSelectLineaColectivo = (markers) => {
    setMarkers(markers);
  };

  const handleMostrarPosicionTrenes = (markers) => {
    getMyLocation();
    setMarkers(markers);
  };
  const onShareClick = (socialRed) => {
    ReactGA.event({
      category: "share",
      action: socialRed,
    });
  };
  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLocalizacionUsuario([
            {
              iconSize: [100, 100],
              descripcion: "",
              label: "",
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              icon: "currentLocation.png",
            },
          ]);
        },
        (error) => {
          setLocalizacionUsuario([
            {
              iconSize: [80, 80],
              descripcion: "",
              label: "",
              position: {
                lat: -34.61315,
                lng: -58.37723,
              },
              icon: "currentLocation.png",
            },
          ]);
        }
      );
    }
  };

  const onTextChangeNombreLinea = (nombreLinea) => {
    setLoading(true);
    setNombreLinea(nombreLinea);
    onBuscarPosicionTransportes(nombreLinea);
  };

  const onBuscarPosicionTransportes = (nombreLinea) => {
    // const api_url = getUrlAgenciasTransporteByLinea(nombreLinea);
    // //console.log(api_url);
    // fetch(api_url)
    //   .then((resolve) => {
    //     return resolve.json();
    //   })
    //   .then((data) => {
    //     //console.log(data);
    //     setRecorridosColectivos(data);
    BuscarPosicionTransportes(nombreLinea);
    // })
    // .catch((rejected) => {
    //   //console.log(rejected);
    //   setLoading(false);
    // });
  };

  const BuscarPosicionTransportes = (nombrelinea) => {
    getMyLocation();
    //setMarkers([]);
    //setStops_markers([]);
    //let currentComponent = this;

    const listOfMarker = [];
    const listOfStopsMarker = [];
    // _recorridosColectivos.forEach((item) => {
    ////console.log(item.nombreAbreviado);
    // let infoLinea = item;
    // const api_transporte_urlPosicionColectivos = getUrlApiPosicionColectivoByIdRecorrido(
      const api_transporte_urlPosicionColectivos =
      getUrlApiGetPosicionByAgencAsyncy(nombrelinea);
   
    // const api_transporte_urlPosicionColectivos =
    //   getUrlApiGetPosicionSimpleByAgencAsync(nombrelinea);
    // const api_transporte_urlParadasColectivos =
    //   getUrlApiParadasColectivoByIdRecorrido(infoLinea.id);

    // fetch(api_transporte_urlParadasColectivos)
    //   .then(resolve => {
    //     return resolve.json();
    //   }).then(result => {
    //     let paradasMarker = transformParadasToListOfMarker(result, infoLinea);

    //     paradasMarker.forEach(mkr => {
    //       listOfStopsMarker.push(mkr)
    //     });

    //     return listOfStopsMarker;
    //   }
    //   ).then(listOfStopsMarker => {
    //     // currentComponent.setState({
    //     //   stops_markers: listOfStopsMarker
    //     // })
    //   })
    //   .catch(rejected => {
    //     //console.log(rejected);
    //   }).finally(() => {
    //   })
    console.log();
    fetch(api_transporte_urlPosicionColectivos)
      .then((resolve) => {
        return resolve.json();
      })
      .then((resolve) => {
        console.log(resolve);
        let data = resolve;

        // let posicionesTransportes = transformColectivoSimpleJsonToListOfMarker(
        //   data,
        //   nombreLinea
        // );
        let posicionesTransportes = transformColectivoJsonToListOfMarker(
          data,
          nombreLinea
        );

        posicionesTransportes.forEach((mkr) => {
          listOfMarker.push(mkr);
        });
        //console.log("BuscarPosicionTransportes A " + listOfMarker.length);
        //console.log(listOfMarker);
        return listOfMarker;
      })
      .then((listOfMarker) => {
        setMarkers(listOfMarker);

        if (listOfMarker.length > 0)
          setMapProps({
            options: {
              center: { lat: -34.61315, lng: -58.37723 },
              zoom: 11,
              streetViewControl: false,
              gestureHandling: "greedy",
            },
            onMountProps: listOfMarker,
          });
        //console.log("BuscarPosicionTransportes B " + listOfMarker.length);
      })
      .catch((rejected) => {
        //console.log(rejected);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // }// );
  };

  function addMarkers(map, links) {
    //console.log("addMarkers " + markers.length);
    markers.forEach((link, index) => {
      const marker = new window.google.maps.Marker({
        map,
        position: {
          lat: marker.position.lat,
          lng: marker.position.lng,
        },
        label: `${index + 1}`,
        title: link.title,
      });
      marker.addListener(`click`, () => {
        window.location.href = link.url;
      });
    });
  }

  const BuildMapa = () => (
    <LoadingOverlay active={loading} spinner text="Buscando...">
      <div
        id="maps"
        style={
          mostrarMapa ? { width: "100vw", height: "90vh" } : { display: "none" }
        }
      >
        <OpenStreetMapView
          markers={markers}
          localizacionUsuario={localizacionUsuario}
          stops_markers={stops_markers}
        />
      </div>
    </LoadingOverlay>
  );
  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem
          button
          key="Colectivos"
          component={Link}
          to="/Colectivos"
          onClick={() => handleDrawerClose(true)}
        >
          <DirectionsBusIcon style={{ fontSize: "50px", color: "#FF0080" }} />{" "}
          <ListItemText primary="Colectivos" />
        </ListItem>

        <Divider />
        <ListItem
          button
          key="Trenes"
          component={Link}
          to="/Trenes"
          onClick={() => handleDrawerClose(true)}
        >
          <ListItemIcon>
            <TrainIcon
              color="primary"
              style={{ fontSize: "50px", color: "#FF0080" }}
            />
          </ListItemIcon>
          <ListItemText primary="Trenes" />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="Subtes"
          component={Link}
          to="/Subtes"
          onClick={() => handleDrawerClose(false)}
        >
          <ListItemIcon>
            <TrainIcon
              color="primary"
              style={{ fontSize: "50px", color: "#FF0080" }}
            />
          </ListItemIcon>
          <ListItemText primary="Subtes" />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="btnContacto"
          component={Link}
          to="/ContactUs"
          onClick={() => handleDrawerClose(false)}
        >
          <ListItemIcon>
            <RecentActors
              color="primary"
              style={{ fontSize: "50px", color: "#FF0080" }}
            />
          </ListItemIcon>
          <ListItemText primary="Contacto" />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="btnAbout"
          component={Link}
          to="/ContactUs"
          onClick={() => handleDrawerClose(false)}
        >
          <ListItemIcon>
            <Info
              color="inherit"
              style={{ fontSize: "50px", color: "#FF0080" }}
            />
          </ListItemIcon>
          <ListItemText primary="Info del sitio" />
        </ListItem>

        <Divider />
        <ListItem>
          {/* <img
            src={require("../img/share.png")}
            style={({ width: 30 }, { height: 30 })}
          /> */}

          {/* <InstapaperShareButton
            title={""}
            beforeOnClick={() => {
              onShareClick("Instapaper");
            }}
            url={"https://batransporte.com"}
          >
            <InstapaperIcon size={32} round={true} />
          </InstapaperShareButton> */}
          <WhatsappShareButton
            beforeOnClick={() => {
              onShareClick("Whatsapp");
            }}
            title={""}
            url={"https://voybondi.somee.com"}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <FacebookShareButton
            beforeOnClick={() => {
              onShareClick("Facebook");
            }}
            title={""}
            url={"https://batransporte.com"}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          {/* <TwitterShareButton
            beforeOnClick={() => {
              onShareClick("Twitter");
            }}
            title={""}
            url={"https://batransporte.com"}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton> */}
          {/* <EmailShareButton
            beforeOnClick={() => {
              onShareClick("Email");
            }}
            title={""}
            url={"https://batransporte.com"}
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton> */}
        </ListItem>
      </List>
    </div>
  );

  function routerList() {
    return (
      <main
        style={{
          display: "flex",

          padding: 1,
          flexDirection: "column",
        }}
      >
        <div
          id="menu_div"
          style={{
            width: "100vw",
            height: "70px",
            border: "0px solid red",
            paddingBotton: "0px",
          }}
        >
          <Switch>
            <Route path="/colectivos">
              <Colectivos
                recorridosColectivos={recorridosColectivos}
                onSelectedidRecorridoColectivo={handleSelectLineaColectivo}
                onTextChangeNombreLinea={onTextChangeNombreLinea}
                onBuscarPosicionTransportes={onBuscarPosicionTransportes}
              />
            </Route>
            <Route path="/Trenes">
              <Trenes
                recorridosColectivos={recorridosColectivos}
                onSelectedidRecorridoColectivo={handleSelectLineaColectivo}
                onTextChangeNombreLinea={onTextChangeNombreLinea}
                onMostrarPosicionTrenes={handleMostrarPosicionTrenes}
              />
            </Route>
            <Route path="/ContactUs">
              <ContactUs />
            </Route>
            <Route path="/Subtes">
              <Subtes />
            </Route>
            <Divider />

            <Route path="/">
              <Colectivos
                recorridosColectivos={recorridosColectivos}
                onSelectedidRecorridoColectivo={handleSelectLineaColectivo}
                onTextChangeNombreLinea={onTextChangeNombreLinea}
                onBuscarPosicionTransportes={onBuscarPosicionTransportes}
              />
            </Route>
          </Switch>
        </div>
        <LoadingOverlay active={loading} spinner text="Buscando...">
          <div
            id="maps"
            style={
              mostrarMapa
                ? {
                    width: "100vw",
                    height: "90vh",
                    border: "0px solid red",
                    paddingBotton: "1px",
                  }
                : { display: "none" }
            }
          >
            {BuildMapa()}
          </div>
        </LoadingOverlay>
      </main>
    );
  }

  //const routeResult = useRoutes(routes);

  return (
    <div>
      <AppBar position="static" style={{ background: "#FF0080" }}>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            BA Transporte v1.1
          </Typography>
        </Toolbar>
      </AppBar>
      <Router>
        <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
          {sideList("left")}
        </Drawer>

        {routerList()}
      </Router>
    </div>
  );
}
