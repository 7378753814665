const transformTrenJsonToListOfMarker = data => (

    data.map(item => ({


        label: item.vehicle.vehicle.label +" | "+ item.vehicle.trip.route_id +
            //" " + (item.vehicle.trip.schedule_relationship === 1 ? 'vuelta' : 'ida') +
            " | " + item.vehicle.current_stop_sequence.toString()
            
        ,
        key: item.id,
        position: {
            lat: item.vehicle.position.latitude,
            lng: item.vehicle.position.longitude
        },
        fecha:item.vehicle.timestamp,
        icon: item.vehicle.trip.schedule_relationship === 1 ? 'tren.png' : 'tren.png',
    }))
);

export default transformTrenJsonToListOfMarker;