import React, { Component } from 'react';
import Layout from './components/Layout';
import TemporaryDrawer from './components/TemporaryDrawer';

import './App.css';

class App extends Component {
  render() {
    return   <div style={{padding:'auto',margin:'auto'}}>
      <TemporaryDrawer />
    </div>
  }
}
export default App