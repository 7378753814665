import {
    isIOS
  } from "react-device-detect";
  

const transformColectivoSimpleJsonToListOfMarker = (data, linea) => (

    // data.filter( mkr=> mkr.position!= undefined ).map(item => ({
    data.map(item => ({

        label: item.route_short_name //item._vehicle._trip._route_id +
           + " " + (item.direction === 1 ? 'vuelta' : 'ida')// +
        //" " + item._vehicle._current_stop_sequence.toString()
        ,
        descripcion: item.route_short_name ,
        key: item.id,
        position: {
            lat: item.latitude,
            lng: item.longitude
        },
        iconSize:[40, 40],
        icon: item.direction === 1 ? (isIOS?'busVueltaNoTransparente.png':'busVuelta.png') : (isIOS?'busIdaNoTransparente.png':'busIda.png'),
    }))
);

export default transformColectivoSimpleJsonToListOfMarker;