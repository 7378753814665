import React from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import OpenStreetMapViewMarkers from './OpenStreetMapViewMarkers'
import {Marker,Popup} from 'react-leaflet'
import { CompareArrowsOutlined } from '@material-ui/icons';
const OpenStreetMapView =(props)=> {
  
  
return <MapContainer className="markercluster-map" center={{lat:-34.6315917 , lng:-58.4351744}} zoom={13}>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {
        props.markers && props.markers.length>0 ?
        <OpenStreetMapViewMarkers markers={props.markers} />:
        null
    }
     {
        props.localizacionUsuario && props.localizacionUsuario.length>0 ?
        <OpenStreetMapViewMarkers markers={props.localizacionUsuario} />:
        null
    }






</MapContainer>
    
}

export default OpenStreetMapView