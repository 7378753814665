import React from 'react';
import UnderConstruction from 'react-under-construction';
import 'react-under-construction/build/css/index.css';
import ReactGA from 'react-ga';

ReactGA.pageview('/ConctactUs')

const ConctactUs = () => (
 

 <div id="ContactUs"
  style={{paddingLeft:20,paddingTop:100, paddingBottom:70}}
  >
  <UnderConstruction
    background={{
      textColor: 'Black',
   
    }}
    logo={{
      alt: 'alt text'
    }}
    title={{
      text: 'Contacto'
    }}
    description={{
      text: 'Para obtener mas informacion contactenos a traves de siguiente medios medios ',
      style: {
        maxWidth: '100%',
        maxHeigth:'100%',
        wordWrap: 'break-word',
       }
    }}
    
    links={[
      {
        url: 'https://www.facebook.com/BATransporte-109167110616635',
        image: 'https://image.flaticon.com/icons/svg/220/220200.svg',
      },
      {
        url: 'https://www.twitter.com/juantripode',
        image: 'https://image.flaticon.com/icons/svg/145/145812.svg',
      },
      {
        url: 'https://www.linkedin.com/www.linkedin.com/in/juan-jose-tripode-09b3ba1a',
        image: 'https://image.flaticon.com/icons/svg/145/145807.svg',
      },
      {
        url: 'mailto:jjtripode@gmail.com.com',
        image: 'https://image.flaticon.com/icons/svg/321/321817.svg',
      },
    ]}
  /> 
  </div>
  );

export default ConctactUs;