import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import transformTrenJsonToListOfMarker from '../services/transformTrenJsonToListOfMarker'
import getUrlApiPosicionTrenes from '../services/getUrlApiPosicionTrenes'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-154869922-1');

const style = {
    select: { padding: 30, width: 250, minWidth: 120 },
    formControl: { margin: 1, flexGrow: 1, minWidth: 120, width: 250, }
}

const Colectivos = ({ recorridosColectivos, onSelectedidRecorridoColectivo, onTextChangeNombreLinea, onMostrarPosicionTrenes }) => {
    const [recorridoSeleccionado, setRecorridoSeleccionado] = React.useState('');
    ReactGA.pageview('/trenes');
    const handleSelectedChangeCmbLineas = (event) => {
        const idRecorridoColectivo = event.target.value;

        setRecorridoSeleccionado(event.target.value);

        buscarPosicionTransportes(idRecorridoColectivo);
    }

    const handleChangeTextNombreLinea = (event) => {
        if (event.key === 'Enter') {
            const nombreLinea = event.target.value;
            onTextChangeNombreLinea(nombreLinea);
        }
    }

    const handleClickMostrarTrenes = (event) => {

        ReactGA.event({
            category: 'trenes',
            action: 'handleClickMostrarTrenes'
        });


        buscarPosicionTransportes();
        //onMostrarPosicionTrenes();
    }


    const strToComponents = recorridosColectivos => (
        <div style={{ display: 'flex', margin: 20, alignItems: 'center', padding: 1, flexWrap: 'wrap' }}>
            <FormControl style={{ display: 'flex', margin: 3, width: 250, alignItems: 'center', padding: 1, }}>
                <div>
                    <Button variant="contained" onClick={handleClickMostrarTrenes} style={{'background': '#FF0080', 'color':'white' }} >
                        Mostrar Trenes
</Button>
                </div>

            </FormControl>
        </div>
    )

    const buscarPosicionTransportes = () => {
        const api_transporte_url = getUrlApiPosicionTrenes();
        fetch(api_transporte_url)
            .then(resolve => {
                return resolve.json();
            })
            .then(data => {
                const listOfMarker = transformTrenJsonToListOfMarker(data.entity)
                onSelectedidRecorridoColectivo(listOfMarker)
            })
            .catch(rejected => {
                //console.log(rejected);
            });
    }

    return (
        <div id="trenes" >
            {strToComponents(recorridosColectivos)}
        </div>
    )

    Colectivos.propTypes = {
        recorridosColectivos: PropTypes.array.isRequired,
        onSelectedidRecorridoColectivo: PropTypes.func,
        onTextChangeNombreLinea: PropTypes.func,
    }
}

export default Colectivos;