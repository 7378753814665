import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import getUrlAgenciasTransporteByLinea from "../services/getUrlAgenciasTransporte";
import Colectivos from "../components/Colectivos";
import Subtes from "../components/Subtes";
import Trenes from "../components/Trenes";
import Mapa from "../components/Mapa";
import ContactUs from "../components/ContactUs";
import getUrlApiPosicionColectivoByIdRecorrido from "../services/getUrlApiPosicionColectivoByIdRecorrido";
import getUrlApiGetPosicionSimpleByAgencAsync from "../services/getUrlApiGetPosicionSimpleByAgencAsync";
import getUrlApiParadasColectivoByIdRecorrido from "../services/getUrlApiParadasColectivoByIdRecorrido";
import transformColectivoJsonToListOfMarker from "../services/transformColectivoJsonToListOfMarker";
import transformColectivoSimpleJsonToListOfMarker from "../services/transformColectivoSimpleJsonToListOfMarker";
import transformParadasToListOfMarker from "../services/transformParadasToListOfMarker";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrainIcon from "@material-ui/icons/Train";
import RecentActors from "@material-ui/icons/RecentActors";
import Info from "@material-ui/icons/Info";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import ReactGA from "react-ga";
import ActivarLocatizacionDialog from "../components/ActivarLocatizacionDialog";
import Demo from "../components/DetectarUbicacionActiva";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from "react-share";
import LoadingOverlay from "react-loading-overlay";

ReactGA.initialize("UA-154869922-1");

const drawerWidth = 240;

const useStyles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

class Layout extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      recorridosColectivos: [],
      open: true,
      nombreLinea: null,
      localizacionUsuario: {},
      loading: false,
      mostrarMapa: true,
    };

    this.BuscarPosicionTransportes = this.BuscarPosicionTransportes.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview("/mainpageLoad");

    ReactGA.event({
      category: "mainPage",
      action: "load",
    });

    this._isMounted = true;
    this.getMyLocation();
  }

  getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState({
            localizacionUsuario: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        },
        (error) => {
          this.setState({ lat: 34, lng: 55 });
        }
      );
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = (mostrarMapa) => {
    this.setState({ open: false });
    this.setState({ mostrarMapa: mostrarMapa });
    this.setState({ markers: [] });
  };

  handleSelectLineaColectivo = (markers) => {
    this.setState({ markers });
  };

  handleMostrarPosicionTrenes = (markers) => {
    this.getMyLocation();
    this.setState({ markers });
  };

  onTextChangeNombreLinea = (nombreLinea) => {
    this.setState({ loading: true });
    this.setState({ nombreLinea });
    this.onBuscarPosicionTransportes(nombreLinea);
  };

  onBuscarPosicionTransportes = (nombreLinea) => {
    const api_url = getUrlAgenciasTransporteByLinea(nombreLinea);
    fetch(api_url)
      .then((resolve) => {
        return resolve.json();
      })
      .then((data) => {
        //console.log(data);
        this.setState({
          recorridosColectivos: data,
        });

        this.BuscarPosicionTransportes();
      })
      .catch((rejected) => {
        //console.log(rejected);
        this.setState({ loading: false });
      });
  };

  onShareClick = (socialRed) => {
    ReactGA.event({
      category: "share",
      action: socialRed,
    });
  };

  BuscarPosicionTransportes = () => {
    this.getMyLocation();
    this.setState({
      markers: [],
      stops_markers: [],
    });
    let currentComponent = this;

    const listOfMarker = [];
    const listOfStopsMarker = [];
    this.state.recorridosColectivos.forEach((item) => {
      ////console.log(item.nombreAbreviado);
      let infoLinea = item;
      // const api_transporte_urlPosicionColectivos = getUrlApiPosicionColectivoByIdRecorrido(infoLinea.id);
      const api_transporte_urlPosicionColectivos =
        getUrlApiGetPosicionSimpleByAgencAsync(infoLinea.id);
      // const api_transporte_urlParadasColectivos = getUrlApiParadasColectivoByIdRecorrido(infoLinea.id);

      // fetch(api_transporte_urlParadasColectivos)
      //   .then(resolve => {
      //     return resolve.json();
      //   }).then(result => {
      //     let paradasMarker = transformParadasToListOfMarker(result, infoLinea);

      //     paradasMarker.forEach(mkr => {
      //       listOfStopsMarker.push(mkr)
      //     });

      //     return listOfStopsMarker;
      //   }
      //   ).then(listOfStopsMarker => {
      //     currentComponent.setState({
      //       stops_markers: listOfStopsMarker
      //     })
      //   })
      //   .catch(rejected => {
      //     //console.log(rejected);
      //   }).finally(() => {
      //   })

      fetch(api_transporte_urlPosicionColectivos)
        .then((resolve) => {
          return resolve.json();
        })
        .then((resolve) => {
          let data = resolve;

          // let posicionesTransportes = transformColectivoJsonToListOfMarker(data._entity, infoLinea)
          let posicionesTransportes =
            transformColectivoSimpleJsonToListOfMarker(data._entity, infoLinea);

          posicionesTransportes.forEach((mkr) => {
            listOfMarker.push(mkr);
          });

          return listOfMarker;
        })
        .then((listOfMarker) => {
          currentComponent.setState({
            markers: listOfMarker,
          });
        })
        .catch((rejected) => {
          //console.log(rejected);
          currentComponent.setState({ loading: false });
        })
        .finally(() => {
          currentComponent.setState({ loading: false });
        });
    });
  };

  render() {
    const { markers } = this.state;
    const { stops_markers } = this.state;
    const { localizacionUsuario } = this.state;
    return (
      <Router>
        <div className={useStyles.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(useStyles.appBar, {
              [useStyles.appBarShift]: this.state.open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                className={clsx(
                  useStyles.menuButton,
                  this.state.open && useStyles.hide
                )}
              >
                <MenuIcon />
              </IconButton>

              <Typography variant="h6" noWrap>
                BA Transporte v1.0
              </Typography>
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
            className={useStyles.drawer}
            variant="persistent"
            anchor="left"
            open={this.state.open}
            classes={{}}
          >
            <div className={useStyles.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}></IconButton>
            </div>
            <div style={{ width: 250 }} role="presentation">
              <List>
                <ListItem
                  button
                  key="Colectivos"
                  component={Link}
                  to="/Colectivos"
                  onClick={() => this.handleDrawerClose(true)}
                >
                  <ListItemIcon>
                    <DirectionsBusIcon
                      color="primary"
                      style={{ fontSize: "50px" }}
                    />{" "}
                  </ListItemIcon>
                  <ListItemText primary="Colectivos" />
                </ListItem>

                <Divider />
                <ListItem
                  button
                  key="Trenes"
                  component={Link}
                  to="/Trenes"
                  onClick={() => this.handleDrawerClose(true)}
                >
                  <ListItemIcon>
                    <TrainIcon
                      color="primary"
                      style={{ "font-size": "50px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Trenes" />
                </ListItem>
                <Divider />

                <ListItem
                  button
                  key="btnContacto"
                  component={Link}
                  to="/ContactUs"
                  onClick={() => this.handleDrawerClose(false)}
                >
                  <ListItemIcon>
                    <RecentActors
                      color="primary"
                      style={{ fontSize: "50px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Contacto" />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  key="btnAbout"
                  component={Link}
                  to="/ContactUs"
                  onClick={() => this.handleDrawerClose(false)}
                >
                  <ListItemIcon>
                    <Info color="primary" style={{ fontSize: "50px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Info del sitio" />
                </ListItem>

                <Divider />
                <ListItem>
                  <img
                    src={require("../img/share.png")}
                    style={({ width: 30 }, { height: 30 })}
                  />

                  <InstapaperShareButton
                    title={""}
                    beforeOnClick={() => {
                      this.onShareClick("Instapaper");
                    }}
                    url={"https://batransporte.com"}
                  >
                    <InstapaperIcon size={32} round={true} />
                  </InstapaperShareButton>
                  <WhatsappShareButton
                    beforeOnClick={() => {
                      this.onShareClick("Whatsapp");
                    }}
                    title={""}
                    url={"https://batransporte.com"}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                  <FacebookShareButton
                    beforeOnClick={() => {
                      this.onShareClick("Facebook");
                    }}
                    title={""}
                    url={"https://batransporte.com"}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    beforeOnClick={() => {
                      this.onShareClick("Twitter");
                    }}
                    title={""}
                    url={"https://batransporte.com"}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <EmailShareButton
                    beforeOnClick={() => {
                      this.onShareClick("Email");
                    }}
                    title={""}
                    url={"https://batransporte.com"}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </ListItem>
              </List>
            </div>
          </SwipeableDrawer>
          <main
            style={{
              display: "flex",
              alignItems: "center",
              padding: 1,
              justifyContent: "flex-center",
            }}
          >
            <div id="menu_div">
              <Switch>
                <Route path="/Colectivos" id="men">
                  <Colectivos
                    recorridosColectivos={this.state.recorridosColectivos}
                    onSelectedidRecorridoColectivo={
                      this.handleSelectLineaColectivo
                    }
                    onTextChangeNombreLinea={this.onTextChangeNombreLinea}
                    onBuscarPosicionTransportes={
                      this.onBuscarPosicionTransportes
                    }
                  />
                </Route>
                <Route path="/Trenes">
                  <Trenes
                    recorridosColectivos={this.state.recorridosColectivos}
                    onSelectedidRecorridoColectivo={
                      this.handleSelectLineaColectivo
                    }
                    onTextChangeNombreLinea={this.onTextChangeNombreLinea}
                    onMostrarPosicionTrenes={this.handleMostrarPosicionTrenes}
                  />
                </Route>
                <Route path="/ContactUs">
                  <ContactUs />
                </Route>
                <Route path="/Subtes">
                  <Trenes />
                </Route>
                <Divider />

                <Route path="/">
                  <Colectivos
                    recorridosColectivos={this.state.recorridosColectivos}
                    onSelectedidRecorridoColectivo={
                      this.handleSelectLineaColectivo
                    }
                    onTextChangeNombreLinea={this.onTextChangeNombreLinea}
                  />
                </Route>
              </Switch>
              <LoadingOverlay
                active={this.state.loading}
                spinner
                text="Buscando..."
              >
                <div
                  id="maps"
                  style={
                    this.state.mostrarMapa
                      ? {
                          width: "100vw",
                          height: "100vh",
                          border: "0px solid red",
                        }
                      : { display: "none" }
                  }
                >
                  <Mapa
                    markers={markers}
                    localizacionUsuario={localizacionUsuario}
                    stops_markers={stops_markers}
                  />
                </div>
              </LoadingOverlay>
            </div>
          </main>
        </div>
      </Router>
    );
  }
}
export default Layout;
