import React from 'react'
import {Marker,Tooltip,Popup} from 'react-leaflet'
import L from "leaflet";

var LeafIcon = L.Icon.extend({
  options: {
     iconSize:     [40, 40],
     shadowSize:   [50, 50],
     iconAnchor:   [31, 31],
     shadowAnchor: [4, 62],
     popupAnchor:  [-3, -76],
  }
});


const OpenStreetMapViewMarkers = (props) =>{
const {markers} = props;
//console.log('OpenStreetMapViewMarkers')
//console.log(markers.length)

 const result = markers.map((mkr,i)=>(
<Marker id={i+mkr.label}
  iconSize={mkr.iconSize}
  position={{lat: mkr.position.lat,lng: mkr.position.lng}}
  icon={new LeafIcon({iconUrl: require("../../img/" + mkr.icon),
  iconRetinaUrl: require("../../img/" + mkr.icon)}) }
  >
  <Popup>
                    <span>{mkr.descripcion}</span>
   </Popup>
   <Tooltip direction='right' offset={[-2, -40]} opacity={1} permanent>
                       <span>{mkr.label}</span>
    </Tooltip>

  </Marker>
  
  
))


return(result )

}

export default OpenStreetMapViewMarkers