import { api_transporte_url_base } from "../constants/apiTranspoteUrl";

const getUrlAgenciasTransporteByLinea = (nombreLinea) => {
  if (!nombreLinea) {
    return `${api_transporte_url_base}AgenciaTransporte/recorridos/filter?page=0&pageSize=100`;
  }

  return `${api_transporte_url_base}AgenciaTransporte/recorridos/filter?linea=${nombreLinea}&page=0&pageSize=100`;
};

export default getUrlAgenciasTransporteByLinea;
