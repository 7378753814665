import { isIOS } from "react-device-detect";

const transformColectivoJsonToListOfMarker = (data, linea) =>
  data._entity.map((item, index) => ({
    label: item.nombreLinea,

    key: "colectivo_" + item._id + index,
    position: {
      lat: item._vehicle._position._latitude,
      lng: item._vehicle._position._longitude,
    },
    iconSize: [40, 40],
    icon:
      item._vehicle._trip  !== undefined && item._vehicle._trip !== null 
      && item._vehicle._trip._direction_id === 1 ? "busVuelta.png" : "busIda.png"
        //       ? isIOS
        //   ? "busVueltaNoTransparente.png"
        //   : "busIda.png"
        // : isIOS
        // ? "busIdaNoTransparente.png"
        // : "busIda.png",
  }));

export default transformColectivoJsonToListOfMarker;
