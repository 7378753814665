import React, { Component } from "react";
import PropTypes from 'prop-types';
import moment from 'moment'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
const mapStyles = {
    width: '100vw',
    height: '100vh',
}

class Mapa extends Component {
    constructor(props) {

        super(props);

        this.state = {
            markers: null,
            localizacionUsuario: null,
            stops_markers: null,
        }
    }

    displayMarkers = () => {
        
        // const  markrsAll = []; 

        // this.props.markers.forEach(mkrs => {
        //     mkrs.forEach(mk => {
        //         markrsAll.push(mk)
        //     })
        // })

        //     return !markrsAll
        //     ? null
        //     : markrsAll.map(mkr => (

        //         <Marker
        //             label={
        //                 mkr.label
        //             }
        //             key={mkr.key}
        //             position={{
        //                 lat: mkr.position.lat,
        //                 lng: mkr.position.lng
        //             }}
        //             icon={{ url: require('../img/' + mkr.icon), scaledSize: { width: 45, height: 45 } }}
        //             onClick={() => alert("You clicked me!")}
        //         ></Marker>
        //    ))
       //console.log(this.props.markers.length)
        return !this.props.markers
            ? null
            : this.props.markers.map(mkr => (
                <Marker style={{ backgroundColor: 'blue', fontSize: '35px', cursor: 'pointer' }}
                    // label={mkr.label }
                    label={{
                        text: mkr.label + ' ' ,//+ moment(mkr.fecha).fromNow(),//;  ,
                        fontFamily: "Arial",
                        fontSize: "22px",
                        color: 'blue',
                        background:'red'
                        //fontWeight: 'bold',

                    }}
                    labelStyle={{ backgroundColor: "yellow", fontSize: "32px", padding: "16px" }}

                    key={mkr.position.lat}
                    position={{
                        lat: mkr.position.lat,
                        lng: mkr.position.lng
                    }}
                    icon={{ url: require('../img/' + mkr.icon), scaledSize: { width: 45, height: 45 } }}
                    onClick={() => alert(mkr.descripcion)}
                ></Marker>
            ))
    }
    displayMarkersLocalizacionUsuario = () => {
        return !this.props.localizacionUsuario
            ? null
            :
            <Marker
                label={
                    'Tu posicion'
                }
                key={1}
                icon={
                    {
                        url: require('../img/currentLocation.png'),
                        scaledSize: {
                            width: 45,
                            height: 45
                        }
                    }
                }
                position={{
                    lat: this.props.localizacionUsuario.lat,
                    lng: this.props.localizacionUsuario.lng
                }}
            // onClick={() => alert("Tu posicion")}
            //options={{ icon: `http://maps.google.com/mapfiles/marker_green.png` }}
            ></Marker>
    }


    displayStopsMarkers = () => {
        return !this.props.stops_markers
            ? null
            : this.props.stops_markers.map(mkr => (

                <Marker
                    label={
                        mkr.label
                    }
                    key={mkr.key}
                    position={{
                        lat: mkr.position.lat,
                        lng: mkr.position.lng
                    }}
                    icon={{ url: require('../img/' + mkr.icon), scaledSize: { width: 45, height: 45 } }}
                    onClick={() => alert(mkr.label)}
                ></Marker>
            ))
    }
    render() {
        const { localizacionUsuario } = this.props;

        return (

            <Map id="map_id_34"
                google={this.props.google}
                zoom={12}
                streetViewControl={false}
                gestureHandling={'greedy'}
                style={mapStyles}
                initialCenter={{
                    lat: !localizacionUsuario ? localizacionUsuario.lat : -34.6131500
                    , lng: !localizacionUsuario ? localizacionUsuario.lng : -58.3772300
                }}
            >
                {this.displayMarkers()}
                {this.displayMarkersLocalizacionUsuario()}
                {this.displayStopsMarkers()}
            </Map>
        );
    }

}
Mapa.propTypes = {
    markers: PropTypes.array.isRequired,
}
export default GoogleApiWrapper({
      apiKey: 'AIzaSyBBCAlD2igB6r6ht014_2_pSYV4DoeX83U'
   //apiKey: 'AIzaSyBBCAlD2igB6r6ht014_2_pSYV4DoeX83U'
    , streetViewControl: false
})(Mapa);