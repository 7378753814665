import React, { Component } from 'react'
import { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import transformColectivoJsonToListOfMarker from '../services/transformColectivoJsonToListOfMarker'
import getUrlApiPosicionColectivoByIdRecorrido from '../services/getUrlApiPosicionColectivoByIdRecorrido'
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-154869922-1');
const style = {
    select: { padding: 30, width: 250, minWidth: 120 },
    formControl: { margin: 1, flexGrow: 1, minWidth: 120, width: 250, }
}

const Colectivos = ({ recorridosColectivos, onSelectedidRecorridoColectivo, onTextChangeNombreLinea }) => {
    const [recorridoSeleccionado, setRecorridoSeleccionado] = React.useState('');
    const [input, setInput] = React.useState('');
    ReactGA.pageview('/colectivos');

    const handleClickBuscar = () => {

        ReactGA.event({
            category: 'colectivos',
            action: 'handleClickBuscar ' + input 
          });
       
        onTextChangeNombreLinea(input.toLocaleUpperCase());
    }

    const handleTextChange =(event)=>{
        setInput(event.target.value);
      }
    const handleKeyDown =(event)=>{
        if(event.key === 'Enter'){
            handleClickBuscar()
        }
      }

    const strToComponents = recorridosColectivos => (
        <div id="div_colectivos" style={{ display: 'flex', 'width':'100%', marginLeft: 10, marginTop:0, marginRigth:10,marginBotton:0, alignItems: 'center', padding: 1, 'flexDirection': 'row' }}>
            <FormControl  id="frmColectivos" style={{ display: 'flex','width':'100%', margin: 3, alignItems: 'center', padding: 1,'flexDirection': 'row' }}>
                
                    <TextField 
                        id="txtIngresoManualLinea"
                        style={{ marginLeft: 1, marginRight: 1, width: '90%' }}
                        label="Ingrese linea, ejemplo 132 "
                        onChange={(e) => {
                            handleTextChange(e)
                        }}
                        onKeyDown={(e)=>handleKeyDown(e)}
                    />
                    <Fab style={{'background': '#FF0080', 'color':'white'}} aria-label="Buscar" onClick={handleClickBuscar} >
                        <SearchIcon   />
                    </Fab>
               
            </FormControl>
         </div>
    )

    
    return (
        <div id="colectivos" style={{paddingLeft:0,paddingTop:0}}>
            {strToComponents(recorridosColectivos)}
        </div>
    )

    Colectivos.propTypes = {
        recorridosColectivos: PropTypes.array.isRequired,
        onSelectedidRecorridoColectivo: PropTypes.func,
        onTextChangeNombreLinea: PropTypes.func,
    }
}

export default Colectivos;